import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Med Ball Cleans/ Stone Cleans`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`2 Lap Run`}</p>
    <p>{`5 Rope Climbs (15′)`}</p>
    <p>{`10 Stone Cleans (100/60)`}</p>
    <p>{`20 GHD Situps`}</p>
    <p>{`30 Box Jumps (24/20)`}</p>
    <p>{`40 Burpees`}</p>
    <p>{`50 Wall Balls`}</p>
    <p>{`2 Lap Run`}</p>
    <p><strong parentName="p"><em parentName="strong">{` `}{`*`}{`We will have to be judged today at the Ville from 10:30-12:30.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      